import React, { useState, useEffect } from 'react';
import { Clock, AlertCircle, ChevronLeft, ChevronRight, BookmarkIcon } from 'lucide-react';
import AttemptAnalysis from './AttemptAnalysis';

type Subject = {
  total: number;
  answered: number;
};

// Define the Subjects interface
interface Subjects {
  [subject: string]: { total: number; answered: number };
}

type Answers = {
  [key: string]: number[]; // Array of question numbers answered for each subject
};

type MarkedForReview = {
  [key: string]: number[]; // Array of question numbers marked for review for each subject
};

const testData  = {
  "id": 1,
  "name": "Comprehensive Test",
  "description": "A test covering multiple subjects",
  "start_time": "2025-02-11T16:50:00Z",
  "duration_minutes": 10,
  "subjects": [
    {
      "id": 1,
      "name": "Mathematics",
      "questions": [
        {
          "id": 1,
          "question_text": "What is 2 + 2?",
          "options": ["1", "2", "3", "4"],
          "correct_option": 3
        },
        {
          "id": 2,
          "question_text": "What is 3 + 5?",
          "options": ["5", "6", "7", "8"],
          "correct_option": 3
        },
        {
          "id": 3,
          "question_text": "What is the square root of 64?",
          "options": ["6", "7", "8", "9"],
          "correct_option": 2
        },
        {
          "id": 4,
          "question_text": "What is 5! (5 factorial)?",
          "options": ["120", "60", "20", "5"],
          "correct_option": 0
        },
        {
          "id": 5,
          "question_text": "Solve for x: 2x + 3 = 7",
          "options": ["2", "3", "4", "5"],
          "correct_option": 0
        },
        {
          "id": 6,
          "question_text": "What is the value of π (pi) approximately?",
          "options": ["2.14", "3.14", "4.14", "5.14"],
          "correct_option": 1
        },
        {
          "id": 7,
          "question_text": "If a triangle has angles 60° and 90°, what is the third angle?",
          "options": ["60°", "30°", "90°", "45°"],
          "correct_option": 1
        }
      ]
    },
    {
      "id": 2,
      "name": "Biology",
      "questions": [
        {
          "id": 8,
          "question_text": "What is the powerhouse of the cell?",
          "options": ["Nucleus", "Mitochondria", "Ribosome", "Golgi Apparatus"],
          "correct_option": 1
        },
        {
          "id": 9,
          "question_text": "Which organ in the human body produces insulin?",
          "options": ["Liver", "Pancreas", "Kidney", "Stomach"],
          "correct_option": 1
        },
        {
          "id": 10,
          "question_text": "What is the basic unit of life?",
          "options": ["Atom", "Molecule", "Cell", "Tissue"],
          "correct_option": 2
        },
        {
          "id": 11,
          "question_text": "Which part of the plant is responsible for photosynthesis?",
          "options": ["Root", "Stem", "Leaf", "Flower"],
          "correct_option": 2
        },
        {
          "id": 12,
          "question_text": "What is the function of red blood cells?",
          "options": ["Transport oxygen", "Fight infections", "Clot blood", "Produce hormones"],
          "correct_option": 0
        }
      ]
    },
    {
      "id": 3,
      "name": "Chemistry",
      "questions": [
        {
          "id": 13,
          "question_text": "What is the chemical formula of water?",
          "options": ["H2O", "CO2", "O2", "NaCl"],
          "correct_option": 0
        },
        {
          "id": 14,
          "question_text": "What is the pH of a neutral solution?",
          "options": ["7", "0", "14", "1"],
          "correct_option": 0
        },
        {
          "id": 15,
          "question_text": "Which gas is used in fire extinguishers?",
          "options": ["Oxygen", "Carbon Dioxide", "Nitrogen", "Hydrogen"],
          "correct_option": 1
        },
        {
          "id": 16,
          "question_text": "Which metal is the most reactive?",
          "options": ["Gold", "Iron", "Sodium", "Copper"],
          "correct_option": 2
        },
        {
          "id": 17,
          "question_text": "What is the main component of natural gas?",
          "options": ["Methane", "Propane", "Butane", "Ethane"],
          "correct_option": 0
        }
      ]
    },
    {
      "id": 4,
      "name": "Physics",
      "questions": [
        {
          "id": 18,
          "question_text": "What is the SI unit of force?",
          "options": ["Newton", "Joule", "Watt", "Pascal"],
          "correct_option": 0
        },
        {
          "id": 19,
          "question_text": "What type of energy is stored in a stretched rubber band?",
          "options": ["Kinetic energy", "Chemical energy", "Elastic potential energy", "Thermal energy"],
          "correct_option": 2
        },
        {
          "id": 20,
          "question_text": "What is the speed of light in vacuum?",
          "options": ["3 × 10^8 m/s", "3 × 10^6 m/s", "3 × 10^4 m/s", "3 × 10^2 m/s"],
          "correct_option": 0
        },
        {
          "id": 21,
          "question_text": "Who formulated the three laws of motion?",
          "options": ["Albert Einstein", "Isaac Newton", "Galileo Galilei", "Nikola Tesla"],
          "correct_option": 1
        },
        {
          "id": 22,
          "question_text": "Which type of mirror is used in vehicle side mirrors?",
          "options": ["Convex", "Concave", "Plane", "Parabolic"],
          "correct_option": 0
        }
      ]
    },
    {
      "id": 5,
      "name": "Social Science",
      "questions": [
        {
          "id": 23,
          "question_text": "Who was the first Prime Minister of India?",
          "options": ["Mahatma Gandhi", "Jawaharlal Nehru", "Sardar Patel", "Dr. B. R. Ambedkar"],
          "correct_option": 1
        },
        {
          "id": 24,
          "question_text": "Which country is known as the ‘Land of the Rising Sun’?",
          "options": ["India", "China", "Japan", "South Korea"],
          "correct_option": 2
        },
        {
          "id": 25,
          "question_text": "Which Indian state has the largest population?",
          "options": ["Bihar", "Uttar Pradesh", "Maharashtra", "West Bengal"],
          "correct_option": 1
        },
        {
          "id": 26,
          "question_text": "What is the main function of the Parliament in India?",
          "options": ["Judicial decisions", "Making laws", "Enforcing laws", "Conducting elections"],
          "correct_option": 1
        },
        {
          "id": 27,
          "question_text": "Which is the longest river in the world?",
          "options": ["Amazon", "Nile", "Ganga", "Yangtze"],
          "correct_option": 1
        }
      ]
    }
  ]
}


const TestTakingUI: React.FC = () => {
  // const [currentSubject, setCurrentSubject] = useState<string>('Physics');
  const [currentQuestion, setCurrentQuestion] = useState<number>(() => {
    const savedQuestion = localStorage.getItem('currentQuestion');
    return savedQuestion ? parseInt(savedQuestion) : 1;
  });
  const [answers, setAnswers] = useState<{ [key: string]: { [key: string]: number } }>({});
  const [currentQtext, setCurrentQtext] = useState<string>('');
  const [timer, setTimer] = useState<number>(() => {
    const savedTime = localStorage.getItem('timer');
    return savedTime ? parseInt(savedTime) : testData.duration_minutes*60;
  });
  // const [answers, setAnswers] = useState<Answers>(() => {
  //   const savedAnswers = localStorage.getItem('answers');
  //   return savedAnswers ? JSON.parse(savedAnswers) : {};
  // });
  const [markedForReview, setMarkedForReview] = useState<MarkedForReview>(() => {
    const savedMarkedForReview = localStorage.getItem('markedForReview');
    return savedMarkedForReview ? JSON.parse(savedMarkedForReview) : {};
  });
  const [showSubmitView, setShowSubmitView] = useState<boolean>(false); // New state for showing the submit view
 
  // edit this according to questions.json
  // Create the subjects data
  const subjects: Subjects = {};

  // Loop through the subjects in the test data
  testData.subjects.forEach((subject) => {
    subjects[subject.name] = {
      total: subject.questions.length, // Total number of questions
      answered: 0, // Initially set to 0 as not answered yet
    };
  });
  const [currentSubject, setCurrentSubject] = useState<string>(testData.subjects[0].name);

  const [testSubmitted, setTestSubmitted] = useState<boolean>(false); // New state for submission


  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 0) {
          clearInterval(interval);
          setTestSubmitted(true); // Automatically submit the test when timer reaches 0
          return 0;
        }
        const updatedTime = prev - 1;
        return updatedTime;
      });
    }, 1000);
  
    return () => clearInterval(interval);
  }, []);
  

  // useEffect(() => {
  //   localStorage.setItem('answers', JSON.stringify(answers));
  //   console.log(answers);
    
  // }, [answers]);

  useEffect(() => {
    localStorage.setItem('markedForReview', JSON.stringify(markedForReview));
  }, [markedForReview]);

  useEffect(() => {
    localStorage.setItem('currentQuestion', currentQuestion.toString());
  }, [currentQuestion]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  const getProgressWidth = (subject: string): string => {
    const answeredCount = Object.keys(answers[subject] || {}).length; // Count answered questions
    const totalQuestions = subjects[subject]?.total || 1; // Avoid division by zero
    return `${(answeredCount / totalQuestions) * 100}%`;
  };
  

  const handleAnswerChange = (subject: string, questionId: number, optionIndex: number) => {
    setAnswers(prevAnswers => {
      const currentAnswer = prevAnswers[subject]?.[questionId] ?? null;
  
      // If the same option is clicked again, unanswer the question
      const updatedAnswer = currentAnswer === optionIndex + 1 ? null : optionIndex + 1;
      console.log(answers);
      
      return {
        ...prevAnswers,
        [subject]: {
          ...prevAnswers[subject],
          [questionId]: updatedAnswer ?? undefined, // Remove key if null
        },
      };
    });
  };
  

  const toggleMarkForReview = (subject: string, question: number) => {
    setMarkedForReview((prev) => {
      const subjectMarks = prev[subject] || [];
      if (subjectMarks.includes(question)) {
        return {
          ...prev,
          [subject]: subjectMarks.filter((q) => q !== question),
        };
      } else {
        return {
          ...prev,
          [subject]: [...subjectMarks, question],
        };
      }
    });
  };

  const goToNextSubject = () => {
    const subjectsArray = Object.keys(subjects);
    const currentIndex = subjectsArray.indexOf(currentSubject);
    const nextSubjectIndex = (currentIndex + 1) % subjectsArray.length;
    setCurrentSubject(subjectsArray[nextSubjectIndex]);
    setCurrentQuestion(1);
  };

  const handleNextQuestion = () => {
    if (currentQuestion < subjects[currentSubject].total) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      goToNextSubject();
    }
  };

  const handleSubmit = () => {
    if (window.confirm("You are submitting the test. You can't solve any question further. Confirm submit?")) {
      alert("Submitting...");
      // Add submission logic here
      setTestSubmitted(true); // Set the test to be submitted
      // console.log({ answers, markedForReview });
    }
  };

  const showSubmitScreen = () => {
    setShowSubmitView(true);
  };

  const goBackToQuestions = () => {
    setShowSubmitView(false);
  };

  if (testSubmitted) {
    return <AttemptAnalysis answers={answers} markedForReview={markedForReview} subjects={subjects} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {showSubmitView ? (
        // Show submit view with question states for all subjects
        <div className="max-w-6xl mx-auto px-4 py-6">
          <div className="bg-white rounded-lg shadow-sm border p-6">
            <h2 className="text-xl font-semibold mb-4">Test Submission Overview</h2>
            {Object.keys(subjects).map((subject) => (
              <div key={subject} className="mb-6">
                <h3 className="text-lg font-semibold">{subject}</h3>
                <div className="grid grid-cols-6 md:grid-cols-10 gap-2">
                  {Array.from({ length: subjects[subject].total }, (_, i) => {
                    const questionNumber = i + 1;
                    const isAnswered = Object.keys(answers[subject] || {}).includes(`${questionNumber}`);
                    const isMarkedForReview = markedForReview[subject]?.includes(questionNumber);
                    return (
                      <button
                        key={questionNumber}
                        className={`p-2 rounded-full text-center transition-colors ${
                          isMarkedForReview
                            ? 'bg-blue-100 text-blue-700'
                            : isAnswered
                            ? 'bg-green-100 text-green-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        {questionNumber}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
            <div className="text-right">
              <button
                className="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700"
                onClick={handleSubmit}
              >
                Confirm Submit
              </button>
            </div>
            <div className="mt-4">
              <button
                className="px-6 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                onClick={goBackToQuestions}
              >
                Back to Questions
              </button>
            </div>
          </div>
        </div>
      ) : (
        // Show test-taking UI
        <>
          <div className="bg-white border-b sticky top-0 z-10">
            <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
              <h1 className="text-2xl font-bold">Competitive Test</h1>
              <div className="flex items-center gap-3">
                {timer <= 300 && (
                  <div className="flex items-center gap-2 text-red-600">
                    <AlertCircle className="w-5 h-5" />
                    <span>Less than 5 minutes remaining!</span>
                  </div>
                )}
                <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-md">
                  <Clock className="w-5 h-5" />
                  <span className="font-mono text-lg">{formatTime(timer)}</span>
                </div>
              </div>
            </div>

            <div className="max-w-6xl mx-auto px-4 py-2">
              <div className="flex space-x-4 overflow-x-auto pb-2">
                {Object.entries(subjects).map(([subject, data]) => (
                  <button
                    key={subject}
                    onClick={() => setCurrentSubject(subject)}
                    className={`group flex-shrink-0 ${
                      currentSubject === subject
                        ? 'text-blue-600'
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    <div className="px-4 py-2">
                      <div className="flex items-center gap-2 mb-1">
                        <span className="font-medium">{subject}</span>
                        <span className="text-sm text-gray-500">
                          {Object.keys(answers[subject] || {}).length || 0}/{data.total}
                        </span>
                      </div>
                      <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
                        <div
                          className={`h-full transition-all duration-300 ${
                            currentSubject === subject
                              ? 'bg-blue-600'
                              : 'bg-gray-400 group-hover:bg-gray-600'
                          }`}
                          style={{ width: getProgressWidth(subject) }}
                        />
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="max-w-6xl mx-auto px-2 py-6">
            <div className="bg-white rounded-lg shadow-sm border p-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">{currentSubject} - Question {currentQuestion}</h2>
                <span className="text-sm text-gray-500">
                  Question {currentQuestion} of {subjects[currentSubject].total}
                </span>
              </div>

              <div className="space-y-6">
                <div className="">
                  {(() => {
                    const subjectData = testData.subjects.find(s => s.name === currentSubject);
                    const question = subjectData?.questions[currentQuestion - 1]?.question_text;
                    const options = subjectData?.questions[currentQuestion - 1]?.options || [];
                    const selectedOption: number | null = answers[currentSubject]?.[currentQuestion] ?? null;

                    return (
                      <div>
                        <p className="font-medium text-red-600">{question}</p>
                        <div className="space-y-3">
                          {options.map((option: string, i: number) => (
                            <label
                              key={i}
                              className={`flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors
                                ${selectedOption !== null && selectedOption !== i + 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                              <input
                                type="radio"
                                name={currentSubject + currentQuestion}
                                value={i + 1}
                                checked={selectedOption === i + 1}
                                onChange={() => handleAnswerChange(currentSubject, currentQuestion, i)}
                                // disabled={selectedOption !== null && selectedOption !== i + 1}
                              />
                              <span>{option}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    );
                  })()}
                </div>




                <div className="flex justify-between items-center pt-6 text-sm">
                  <button
                    className="flex items-center gap-1 px-2 py-2 border rounded-md hover:bg-gray-50"
                    onClick={() => setCurrentQuestion((prev) => Math.max(1, prev - 1))}
                  >
                    <ChevronLeft className="w-4 h-4" /> Previous
                  </button>

                  <div className="flex gap-1">
                    <button
                      className="flex items-center justify-center gap-1 px-2 py-2 border rounded-md hover:bg-gray-50"
                      onClick={() => toggleMarkForReview(currentSubject, currentQuestion)}
                    >
                      <BookmarkIcon className="w-4 h-4" />
                      Mark for Review
                    </button>
                    <button
                      className="flex items-center gap-1 px-2 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      onClick={handleNextQuestion}
                    >
                      Next <ChevronRight className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 bg-white rounded-lg shadow-sm border p-6">
              <h3 className="text-lg font-semibold mb-4">Question Palette</h3>
              <div className="grid grid-cols-6 md:grid-cols-10 gap-2">
                {Array.from({ length: subjects[currentSubject].total }, (_, i) => {
                  const questionNumber = i + 1;
                  const isAnswered =  Object.keys(answers[currentSubject] || {}).includes(`${questionNumber}`);
                  const isMarkedForReview = markedForReview[currentSubject]?.includes(questionNumber);
                  const isCurrent = currentQuestion === questionNumber;

                  return (
                    <button
                      key={questionNumber}
                      onClick={() => setCurrentQuestion(questionNumber)}
                      className={`p-2 rounded-full text-center transition-colors ${
                        isMarkedForReview
                          ? 'bg-blue-100 text-blue-700'
                          : isAnswered
                          ? 'bg-green-100 text-green-700'
                          : isCurrent
                          ? 'bg-gray-300'
                          : 'bg-red-100 text-red-700'
                      }`}
                    >
                      {questionNumber}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="mt-6 bg-white rounded-lg shadow-sm border p-6 text-right">
              <button
                className="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700"
                onClick={showSubmitScreen}
              >
                Submit Test
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TestTakingUI;
