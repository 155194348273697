import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Confetti, ConfettiButton, type ConfettiRef } from "../components/magicui/confetti";
  
type newUsers = {
    name: string;
    phone_number: string;
    address: string;
    test_mode: string;
    test_medium: string;
}

const TestRegistrationForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    address: "",
    test_mode: "OnMobile",
    test_medium: "Hindi",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    address: "",
  });

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [registeredCount, setRegisteredCount] = useState<number | null>(null);
  const [registeredUsers, setRegisteredUsers] = useState<newUsers[]>([]);
  const confettiRef = useRef<ConfettiRef>(null);

  useEffect(() => {
    // Fetch registered student count on component mount
    axios
      .get("https://naendapi.naecoaching.com/naecoaching/naetalenttest/registration-count/")
      .then((response) => {
        setRegisteredCount(response.data.registered_students);
      })
      .catch(() => {
        setRegisteredCount(null);
      });
  }, []);

  useEffect(() => {
    const storedUsers = localStorage.getItem("registeredUsers");
    if (storedUsers) {
      try {
        setRegisteredUsers(JSON.parse(storedUsers));
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
        setRegisteredUsers([]);
      }
    } else {
      setRegisteredUsers([]);
    }
  }, []);
  


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    let valid = true;
    const newErrors = { name: "", phone_number: "", address: "" };

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!/^[0-9]{10}$/.test(formData.phone_number))
      newErrors.phone_number = "Phone number must be 10 digits.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await axios.post(
        "https://naendapi.naecoaching.com/naecoaching/test-registrations/",
        formData
      );
      const newUsers = [...registeredUsers, formData];
      setRegisteredUsers(newUsers);
      localStorage.setItem("registeredUsers", JSON.stringify(newUsers));
      alert(`Congratulations! 🎉 आपका रजिस्ट्रशन हो गया है :\n${newUsers.map(user => `${user.name} - ${user.phone_number}, ${user.address}, ${user.test_mode}, ${user.test_medium}`).join("\n")}`);
      setFormData({
        name: "",
        phone_number: "",
        address: "",
        test_mode: "OnMobile",
        test_medium: "Hindi",
      });
      setRegisteredCount((prev) => (prev !== null ? prev + 1 : null));
      setSuccessMessage("Registration successful!");
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage(null);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-4">Register for Upcoming Test  <br/> on 27 Feb, 2025</h2>
      <h1 className="text-5xl font-bold text-center text-yellow-500 mb-6">
        Win <span className="text-red-500">₹5000</span>
      </h1>

      {/* Display Registered Student Count */}
      {registeredCount !== null && (
        <>
        <p className="text-center text-lg font-semibold text-blue-600 ">
          Already Registered Students: {registeredCount}
        </p>
        <p className="text-center text-lg font-semibold text-blue-600 mb-4">
        {registeredCount} स्टूडेंट्स ने अब तक रजिस्टर कर लिया है 
        </p>
      </>
      )}

      {registeredUsers.length > 0 && (
        <div className="mb-4 p-3 bg-green-100 border border-green-500 rounded-md">
          <h3 className="font-bold text-green-700">You Registered (आपके द्वारा  {registeredUsers.length} रजिस्ट्रशन किया गया है):</h3>
          <ul className="list-disc pl-5">
            {registeredUsers.map((user, index) => (
              <li key={index} className="text-green-700">
                {user.name} - {user.phone_number}, {user.address}, {user.test_mode}, {user.test_medium}
              </li>
            ))}
          </ul>
        </div>
      )}
      {successMessage && <>
        <div className="relative flex h-[400px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-yellow-600 to-red-500/70 bg-clip-text text-center text-4xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10">
        Congratulation 🎉
      </span>
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-red-300/80 bg-clip-text text-center mt-4 py-1 text-xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10">
      आपका रजिस्ट्रशन हो गया है 🎉
      </span>
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-green-500/80 bg-clip-text text-center mt-4 py-1 text-xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10">
      अब जमकर तैयारी करें, और जीतें 5000 तक का ईनाम !  🎉
      </span>
      <Confetti
        ref={confettiRef}
        className="absolute left-0 top-0 z-0 size-full"
        onMouseEnter={() => {
          confettiRef.current?.fire({});
        }}
      />
    </div>
       <p className="text-green-500 text-center mt-4">{successMessage}</p>
       </>}
      {errorMessage && <p className="text-red-500 text-center mt-4">{errorMessage}</p>}


      { (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block font-medium mb-1">Name (नाम):</label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded-md" />
          </div>

          <div>
            <label className="block font-medium mb-1">Phone Number (फ़ोन नंबर):</label>
            <input type="text" name="phone_number" placeholder="9876543210 (10 digit phone number only)" value={formData.phone_number} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded-md" />
          </div>

          <div>
            <label className="block font-medium mb-1">Address (पता):</label>
            <textarea name="address" value={formData.address} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded-md" rows={4}></textarea>
          </div>

          <div>
            <label className="block font-medium mb-1">Test Mode (परीक्षा मोड):</label>
            <div className="flex space-x-4">
              <label>
                <input type="radio" name="test_mode" value="OnMobile" checked={formData.test_mode === "OnMobile"} onChange={handleChange} /> On Mobile
              </label>
              <label>
                <input type="radio" name="test_mode" value="OnPaper" checked={formData.test_mode === "OnPaper"} onChange={handleChange} /> On Paper
              </label>
            </div>
          </div>

          <div>
            <label className="block font-medium mb-1">Test Medium (परीक्षा भाषा):</label>
            <div className="flex space-x-4">
              <label>
                <input type="radio" name="test_medium" value="Hindi" checked={formData.test_medium === "Hindi"} onChange={handleChange} /> Hindi
              </label>
              <label>
                <input type="radio" name="test_medium" value="English" checked={formData.test_medium === "English"} onChange={handleChange} /> English
              </label>
            </div>
          </div>
          
          <button type="submit" onClick={handleSubmit} className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600">
            Register (रजिस्टर करें)
          </button>
        </form>
      )}
      
      {successMessage && <>
        <div className="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300/80 bg-clip-text text-center text-8xl font-semibold leading-none text-transparent dark:from-white dark:to-slate-900/10">
        Confetti
      </span>
 
      <Confetti
        ref={confettiRef}
        className="absolute left-0 top-0 z-0 size-full"
        onMouseEnter={() => {
          confettiRef.current?.fire({});
        }}
      />
    </div>
       <p className="text-green-500 text-center mt-4">{successMessage}</p>
       </>}
      {errorMessage && <p className="text-red-500 text-center mt-4">{errorMessage}</p>}
    </div>
  );
};

export default TestRegistrationForm;
