import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const TalentTest = () => {
  return (
    <>
      <Helmet>
        <title>NAE Talent Test</title>
        <meta property="og:title" content="NAE Talent Test" />
        <meta
          property="og:description"
          content="Join the NAE Talent Test to showcase your skills and win exciting rewards!"
        />
        <meta
          property="og:image"
          content="https://naendapi.naecoaching.com/media/images/Blue_Creative_Illustration_Learning_Poster_CUhuI5Y.png"
        />
        <meta
          property="og:url"
          content="https://www.naecoaching.com/#naetalenttest"
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div id="naetalenttest">
        <Link
          to={"/N_A_E_Talent_Test"}
          className="p-2 rounded-xl shadow-xl flex justify-center items-center"
        >
          <img
            src="https://naendapi.naecoaching.com/media/images/Blue_Creative_Illustration_Learning_Poster_CUhuI5Y.png"
            alt="Talent Test Poster"
          />
        </Link>
      </div>
    </>
  );
};

export default TalentTest;
