import React, { useState, useEffect } from 'react';
import { CheckCircle2, XCircle  } from 'lucide-react';

type Subject = {
  total: number;
  answered: number;
};

type Subjects = {
  [key: string]: Subject;
};

type Answers = {
  [key: string]: { [key: number]: number }  // Array of question numbers answered for each subject
};

type MarkedForReview = {
  [key: string]: number[]; // Array of question numbers marked for review for each subject
};
type QuestionAnswers = {
  [questionNumber: number]: string; // Maps question numbers to answers
};

type SubjectsAnswers = {
  [subject: string]: QuestionAnswers; // Maps subjects to their question-answers
};
interface AttemptAnalysisProps {
  answers: Answers;
  markedForReview: MarkedForReview;
  subjects: Subjects;
}


interface TestResultsProps {
  answers: Answers;
  subjects: Subjects;
}

const TestResults: React.FC<TestResultsProps> = ({ answers, subjects }) => {
  // Calculate the test score, correct answers, and incorrect answers
  const totalQuestions = Object.values(subjects).reduce((acc, subject) => acc + subject.total, 0);
  const correctAnswers = Object.values(answers).reduce((acc, subjectAnswers) => acc + Object.keys(subjectAnswers).length, 0);  const incorrectAnswers = totalQuestions - correctAnswers;

  const score = ((correctAnswers / totalQuestions) * 100).toFixed(1);
  
  // edit these data according to Answers.json
  const correctAnswersA: SubjectsAnswers = {
    Physics: {
      1: '3x10^8 m/s',
      2: '2x10^8 m/s',
      3: '3x10^8 m/s',
      4: '2x10^8 m/s',
      5: '3x10^8 m/s',
      6: '2x10^8 m/s',
      7: '3x10^8 m/s',
      8: '2x10^8 m/s',
      9: '3x10^8 m/s',
      10: '2x10^8 m/s',
      // Correct answers for all questions
    },
    Chemistry: {
      1: 'None of these',
      2: '2x10^8 m/s',
      3: '3x10^8 m/s',
      4: '2x10^8 m/s',
      5: '3x10^8 m/s',
      6: '2x10^8 m/s',
      7: '3x10^8 m/s',
      8: '2x10^8 m/s',
      
      // Correct answers for all questions
    },
    Mathematics: {
      1: '3x10^8 m/s',
      2: '2x10^8 m/s',
      3: '3x10^8 m/s',
      4: '2x10^8 m/s',
      5: '3x10^8 m/s',
      6: '2x10^8 m/s',
      7: '3x10^8 m/s',
      8: '2x10^8 m/s',
      9: '3x10^8 m/s',
      10: '2x10^8 m/s',
      11: '2x10^8 m/s',
      12: '2x10^8 m/s',
      // Correct answers for all questions
    },
    Biology: {
      1: 'None of these',
      2: '2x10^8 m/s',
      3: '3x10^8 m/s',
      4: '2x10^8 m/s',
      5: '3x10^8 m/s',
      6: '2x10^8 m/s',
      7: '3x10^8 m/s',
      8: '2x10^8 m/s',
      9: '3x10^8 m/s',
      10: '2x10^8 m/s',
      // Correct answers for all questions
    },
    // Add correct answers for other subjects...
  };

  const answersA: SubjectsAnswers  = {
    Physics: {
      1: '3x10^8 m/s',
      2: '3x10^7 m/s', // Incorrect answer
      // Other answered questions
    },
    Chemistry: {
      1: 'None of these',
      // Other answered questions
    },
    // Add answers for other subjects...
  };
  return (
    <div className="w-full max-w-4xl mx-auto p-2 space-y-6">
      <h1 className="text-2xl font-bold">Test Results</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white rounded-lg shadow p-4 text-center">
          <h3 className="text-2xl font-bold text-blue-600">{score}%</h3>
          <p className="text-gray-600">Overall Score</p>
        </div>
        <div className="bg-white rounded-lg shadow p-4 text-center">
          <h3 className="text-2xl font-bold text-green-600">{correctAnswers}/{totalQuestions}</h3>
          <p className="text-gray-600">Correct Answers</p>
        </div>
        <div className="bg-white rounded-lg shadow p-4 text-center">
          <h3 className="text-2xl font-bold text-red-600">{incorrectAnswers}/{totalQuestions}</h3>
          <p className="text-gray-600">Incorrect Answers</p>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Detailed Analysis</h2>
        <div className="space-y-4">
        {Object.keys(correctAnswersA).map((subject) => ( // Map over correctAnswersA keys to get each subject
          <div key={subject} className="space-y-6">
            <h3 className="font-medium text-lg">{subject}</h3>
            {Object.keys(correctAnswersA[subject]).map((questionNumberStr) => {
              const questionNumber = Number(questionNumberStr); // Convert string to number
              const answer = answersA[subject]?.[questionNumber];
              const correctAnswer = correctAnswersA[subject]?.[questionNumber];
              const isCorrect = answer === correctAnswer;
              const isAttempted = answer !== undefined;

              return (
                <div key={questionNumber} className="p-2 border rounded-lg">
                  <div className="flex items-start gap-3">
                  {isAttempted ?
                  <>
                    {isCorrect ? (
                      <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
                    ) : (
                      <XCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
                    )}</>  : <XCircle className="w-6 h-6 text-blue-500 flex-shrink-0" />
                    }
                    <div>
                      {/* question from answers.json */}
                      <p className="font-medium">Question {questionNumber}</p>
                      {/* compare the answer given by student and the answer from answers.json */}
                      <p className=" text-red-600 mt-1">
                        {isAttempted
                          ? `${isCorrect ? "" : `Your Answer: ${answer} (Incorrect)`}`
                          : "Not Attempted"}
                      </p>
                      {/* correct Answer from answers.json */}
                        <p className=" text-green-600">Correct Answer: {correctAnswer}</p>
                        {/* Answer's explanation from answers.json */}
                        <p className="text-gray-600 mt-2">The speed of light in vacuum is exactly 3×10^8 meters per second. This is a fundamental constant of nature.</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};



const AttemptAnalysis: React.FC<AttemptAnalysisProps> = ({ answers, markedForReview, subjects }) => { 
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    // Set timeout to show results after 3 minutes
    const timer = setTimeout(() => {
      setShowResults(true);
    }, 18000); // 3 minutes in milliseconds

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, []);

return (
<div className="w-full max-w-4xl mx-auto p-3 space-y-6">
    <h1 className="text-2xl font-bold">Test Submission Analysis</h1>

    <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
      <h2 className="text-xl font-semibold mb-4">Question Attempt Summary</h2>

      <div className="space-y-6">
        {Object.keys(subjects).map((subject) => (
          <div className="border-b pb-4" key={subject}>
            <h3 className="font-medium mb-3">{subject}</h3>
            <div className="grid grid-cols-3 gap-4 text-center">
              <div>
                <p className="text-sm text-gray-500">Total Quest</p>
                <p className="text-lg font-medium">{subjects[subject].total}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Attempted</p>
                <p className="text-lg font-medium text-green-600">
                {Object.keys(answers[subject] || {}).length || 0}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Unattempted</p>
                <p className="text-lg font-medium text-red-600">
                  {subjects[subject].total - (subjects[subject].answered)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {!showResults&& <div className="text-center text-gray-500 mt-4">
        Results will be available in 3 minutes
      </div>}

    </div>
    {showResults && <TestResults answers={answers} subjects={subjects} />}

  </div>
)
};

export default AttemptAnalysis;